import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MapContext } from '../providers/mapProvider';
import { Button, Stack } from 'react-bootstrap';
import Select from 'react-select';
import {
  SelectStylesGLS,
  SelectComponentsGLS
} from '../helpers/SelectStylesGLS';
import { CreateZoneModes } from '../dataGLS/CloserModes';

const FiltersBar = props => {
  const { t } = props;
  const {
    services,
    createZoneMode,
    filterServices,
    setFilterServices,
    postalCodes,
    filterPostalCodes,
    setFilterPostalCodes,
    dateRanges,
    filterDateRanges,
    setFilterDateRanges,
    showStops,
    setShowStops,
    stops
  } = useContext(MapContext);

  const [filterServ, setFilterServ] = useState(filterServices);
  const [filterCP, setFilterCP] = useState(filterPostalCodes);
  const [filterFPE, setFilterFPE] = useState(filterDateRanges);

  useEffect(() => {
    setFilterServices(filterServ);
  }, [filterServ]);

  useEffect(() => {
    setFilterPostalCodes(filterCP);
  }, [filterCP]);

  useEffect(() => {
    setFilterDateRanges(filterFPE);
  }, [filterFPE]);

  //Update value in selects if provider value is updated
  useEffect(() => {
    if (JSON.stringify(filterServices) !== JSON.stringify(filterServ)) {
      updateFilterServices(filterServices);
    }
  }, [filterServices]);

  useEffect(() => {
    if (JSON.stringify(filterPostalCodes) !== JSON.stringify(filterCP)) {
      updateFilterCP(filterPostalCodes);
    }
  }, [filterPostalCodes]);

  useEffect(() => {
    if (JSON.stringify(filterDateRanges) !== JSON.stringify(filterFPE)) {
      updateFilterFPE(filterDateRanges);
    }
  }, [filterDateRanges]);

  const updateFilterServices = value => {
    setFilterServ(value);
  };

  const updateFilterCP = value => {
    setFilterCP(value);
  };

  const updateFilterFPE = value => {
    setFilterFPE(value);
  };

  if (createZoneMode === CreateZoneModes.MAP) {
    return <></>;
  }
  return (
    <>
      <Stack
        className={'bg-surface-light mx-0 p-3 shadow'}
        direction={'horizontal'}
        gap={3}
      >
        <Select
          className={''}
          name={'products'}
          placeholder={t('Service type')}
          options={services.map(service => ({
            value: service.code,
            label: service.name
          }))}
          styles={SelectStylesGLS}
          components={SelectComponentsGLS}
          value={filterServ}
          onChange={value => {
            updateFilterServices(value);
          }}
          isMulti
        />
        <Select
          name={'zips'}
          placeholder={t('ZIP')}
          options={postalCodes.map(pc => ({
            value: pc.code,
            label: pc.code,
            country: pc.country
          }))}
          styles={SelectStylesGLS}
          components={SelectComponentsGLS}
          value={filterCP}
          onChange={value => {
            updateFilterCP(value);
          }}
          isMulti
        />
        <Select
          name={'FPE'}
          placeholder={t('FPE')}
          options={dateRanges.map(pc => ({
            value: pc.code,
            label: pc.name,
            range: pc.dateRange.map(value => new Date(value)),
          }))}
          styles={SelectStylesGLS}
          components={SelectComponentsGLS}
          isClearable
          value={filterFPE}
          onChange={value => {
            updateFilterFPE(value);
          }}
        />
        {/*
        <Col md={5}>
          <Form.Label htmlFor={'clients'} className={'fw-bold mb-0 fs-10'}>
            {t('Clients')}
          </Form.Label>
          <Select
            className={'mb-3'}
            name={'clients'}
            placeholder={t('Select your options')}
            options={[]}
            styles={SelectStylesGLS}
            components={SelectComponentsGLS}
            //value={selectedDriver}
            onChange={value => {
              //changeDriver(value);
            }}
            isMulti
          />
        </Col>*/}
        {stops.length > 0 && (
          <Button
            className={'ms-auto rounded-pill py-2 lh-1'}
            variant={showStops ? 'outline-primary' : 'primary'}
            onClick={() => {
              setShowStops(prevVal => !prevVal);
            }}
          >
            <span className="material-symbols-outlined fs-9">
              {showStops ? 'visibility' : 'visibility_off'}
            </span>
            &nbsp;
            {t('Shipments')}
          </Button>
        )}
      </Stack>
    </>
  );
};

FiltersBar.propTypes = {
  t: PropTypes.func
};
export default withTranslation()(FiltersBar);
