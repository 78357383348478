import { useEffect, useRef } from 'react';
import {
  GeometryTypes,
  polygonOptions,
  PolygonTypes,
  postalCodeOptions
} from '../dataGLS/polygon';
import { CreateZoneModes, MapStates } from '../dataGLS/CloserModes';
import { ZoneDefaultID } from '../dataGLS/zoneData';
import {
  hasOverlappingLines,
  reorderPolygonPointsHole
} from '../helpers/polygonUtils';

// Handle drawing manager events
export function useDrawingManagerEvents(
  drawingManager = null,
  mapProvider,
  selectedZoneRef
) {
  const {
    zonesPrint,
    setZonesPrint,
    setZonesAssign,
    zones,
    setZones,
    polygons,
    setPolygons,
    shipmentAreas = [],
    triggerClearZone = false,
    addPolygon,
    setZonesPrinted,
    createZoneMode,
    stopsAssigned,
    mapState,
    history,
    historySetPresent,
    removePolygon,
    setSelectedZone
  } = mapProvider;
  //To access data inside listeners (update path)
  let selectedZone = useRef(null);
  const polygonsRef = useRef(polygons);
  const mapStateRef = useRef(mapState);
  const zonesRef = useRef(zones);
  const google = window.google;
  useEffect(() => {
    if (!drawingManager) return;
    /*********************************
     Listener when user completes a polygon.
      Check if it has lines overlapping and set a flag
     Save polygon in polygons list and update history
     Add listener to polygon
     And finally add polygon to zone
    **********************************/

    google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      polygon => {
        const path = polygon
          .getPath()
          .getArray()
          .map(c => c.toJSON());
        const overlapping = hasOverlappingLines(path);
        console.log('Overlapping', { overlapping });
        console.log('SelectedZone drawing', {
          selectedZone: selectedZone.current
        });
        // Default ID for new polygons
        const id = `${
          selectedZone.current.polygonID || selectedZone.current.id
        }-${new Date().getTime()}`;
        polygon.id = id;
        const polygonData = {
          id: id,
          geometry: {
            coordinates: [path]
          },
          type: PolygonTypes.Zone,
          overlapping,
          polygon
        };
        console.log('History New drawing', history.current);
        //Add polygon to list
        setPolygons(prevPolygons => {
          const present = [...prevPolygons, polygonData];
          historySetPresent(present);
          return present;
        });
        //Add listeners to polygon
        addPolygonListeners(polygon, polygonData);
        polygon.setEditable(false);
        //Deselect all other polygons
        deselectAllPolygons();
        //Disable drawing mode
        //drawingManager.setDrawingMode(null);
        addPolygon([path]);
      }
    );
    drawShipmentAreas();
  }, [drawingManager]);

  const drawShipmentAreas = () => {
    //Prevent crash
    if (!drawingManager) return;
    //Array to save new polygons
    const newPolygons = [];
    shipmentAreas.forEach(postalCode => {
      //Find in the polygons list the polygons with the postal code id
      const polygonData = polygons.find(polygon => {
        return polygon.id === postalCode.code;
      });
      if (polygonData) return;
      switch (postalCode.polygon.type) {
        case GeometryTypes.Polygon:
          newPolygons.push(
            createPolygonData(
              postalCode.code,
              postalCode.polygon,
              reorderPolygonPointsHole(postalCode.polygon.coordinates),
              PolygonTypes.ShipmentCode
            )
          );
          break;
        case GeometryTypes.MultiPolygon:
          postalCode.polygon.coordinates.forEach((polygon, index) => {
            const geometry = {
              type: GeometryTypes.MultiPolygon,
              coordinates: polygon
            };
            newPolygons.push(
              createPolygonData(
                `${postalCode.code}--${index}`,
                geometry,
                reorderPolygonPointsHole(polygon),
                PolygonTypes.ShipmentCode
              )
            );
          });
          break;
      }
    });
    //Update polygons list
    setPolygons(prevPolygons => [...prevPolygons, ...newPolygons]);
  };

  useEffect(() => {
    drawShipmentAreas();
  }, [shipmentAreas]);

  useEffect(() => {
    console.log('polygons', polygons);
    polygonsRef.current = polygons;
  }, [polygons]);

  const printZone = (zone, newPolygons) => {
    //Find polygons with the zone id
    let polygonData = polygons.filter(polygon => {
      return polygon.id.startsWith(zone.polygonID || zone.id);
    });
    //If there are polygons, set the map to show it
    if (polygonData.length > 0) {
      polygonData.forEach(polygon => {
        polygon.polygon.setMap(drawingManager.getMap());
        polygon.polygon.setPaths(polygon.geometry.coordinates);
        //Add new listeners. When path is modified the listeners are deleted
        addPolygonUpdateListeners(polygon.polygon.getPath(), polygon);
      });
      return newPolygons;
    }
    //IF zone hasn't a geometry, return
    if (!zone.geometry || !zone.geometry.coordinates) return newPolygons;
    //If polygon not exists, create it

    switch (zone.geometry.type) {
      case GeometryTypes.Polygon:
        //Add polygon to news array
        if (zone.geometry.coordinates.length > 0) {
          newPolygons.push(
            createPolygonData(zone.id, zone.geometry, zone.geometry.coordinates)
          );
        }
        break;
      case GeometryTypes.MultiPolygon:
        if (zone.geometry.coordinates.length > 0) {
          zone.geometry.coordinates.forEach((polygon, index) => {
            const geometry = {
              type: GeometryTypes.MultiPolygon,
              coordinates: polygon
            };
            newPolygons.push(
              createPolygonData(`${zone.id}--${index}`, geometry, polygon)
            );
          });
        }
        break;
    }
    console.log('Print new polygons', { newPolygons, zone });
    return newPolygons;
  };

  const createPolygonData = (
    id,
    geometry,
    coordinates,
    type = PolygonTypes.Zone
  ) => {
    let opts = {};
    switch (type) {
      case PolygonTypes.Zone:
        opts = polygonOptions;
        break;
      case PolygonTypes.ShipmentCode:
        opts = postalCodeOptions;
        break;
    }
    const polygonOpts = {
      ...opts,
      paths: coordinates,
      map: drawingManager.getMap()
    };
    console.log('Creating polygon', polygonOpts);
    const polygon = new window.google.maps.Polygon(polygonOpts);
    polygon.id = id;
    const polygonData = {
      id: id,
      geometry: geometry,
      type,
      polygon
    };
    if (type === PolygonTypes.Zone) {
      //Add listeners to polygon
      addPolygonListeners(polygon, polygonData);
    }
    return polygonData;
  };
  useEffect(() => {
    console.log('draw', {
      drawingManager,
      zonesPrint,
      stopsAssigned
    });
    //Prevent crash
    if (!drawingManager) return;
    if (zonesPrint.length === 0) {
      return;
    }
    hideAllZones();
    //Array to save new polygons
    let newPolygons = [];
    //For every zone
    zonesPrint.forEach(zone => {
      console.log('PrintZone');
      if (zone.show) {
        newPolygons = printZone(zone, newPolygons);
      }
    });
    //Update polygons list
    console.log('Drawing data', {
      polygons,
      newPolygons,
      filtered: polygons.filter(
        polygon => !polygon.id.startsWith(ZoneDefaultID)
      ),
      all: [
        ...polygons.filter(polygon => !polygon.id.startsWith(ZoneDefaultID)),
        ...newPolygons
      ]
    });
    //Don't know why filtered
    setPolygons(prevPolygons => [
      //...prevPolygons.filter(polygon => !polygon.id.startsWith(ZoneDefaultID)),
      //...prevPolygons,
      ...(createZoneMode === CreateZoneModes.DISABLED
        ? //? prevPolygons.filter(polygon => !polygon.id.startsWith(ZoneDefaultID))
          prevPolygons
        : prevPolygons),
      ...newPolygons
    ]);
    //Reset zones to print, set it to zones and set printed flag
    setZonesPrint([]);
    if (stopsAssigned) {
      console.log('DrawingMManager setZones');
      setZones(zonesPrint);
    } else {
      console.log('DrawingMManager assign');
      setZonesAssign(zonesPrint);
    }
    setZonesPrinted(true);
  }, [drawingManager, zonesPrint]);

  const hideAllZones = () => {
    //Remove map for every zone polygon to clear map
    console.log('Polygons print hide all', polygons);
    //Prevent crash
    if (!drawingManager) return;
    polygons.forEach(polygonData => {
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
  };

  useEffect(() => {
    hideAllZones();
  }, [triggerClearZone]);

  useEffect(() => {
    mapStateRef.current = mapState;
  }, [mapState]);

  useEffect(() => {
    zonesRef.current = zones;
  }, [zones]);
  useEffect(() => {
    if (drawingManager && createZoneMode === CreateZoneModes.DISABLED) {
      drawingManager.setDrawingMode(null);
    }
  }, [createZoneMode]);

  //Add Google Maps listeners to polygon
  const addPolygonListeners = (polygon, polygonData) => {
    if (!polygon) return;
    console.log('addPolygonListeners', { polygon, polygonData });
    // Remove previous listeners
    google.maps.event.clearListeners(polygon);
    //On click in a polygon
    google.maps.event.addListener(polygon, 'click', () => {
      // Get the updated polygonData from the reference array
      console.log('Listener polygon', polygon.id);
      const currentPolygonData = polygonsRef.current.find(
        p => p.polygon === polygon
      );
      const currentSelectedZone = selectedZone.current;

      if (!currentPolygonData) {
        console.warn('No se encontraron datos del polígono');
        return;
      }

      // Update ID only if it is necessary
      console.log('Click polygon', { polygon, mapState: mapStateRef.current });
      // Check if there is a selected zone and the polygon is part of it
      // AND select zone is not new zone AND current zone is not default
      if (
        !currentSelectedZone ||
        !currentPolygonData.id.startsWith(
          currentSelectedZone.polygonID || currentSelectedZone.id
        )
      ) {
        console.log('El polígono no pertenece a la zona seleccionada');
        return;
      }

      // Depending on map state mode, it has different actions
      switch (mapStateRef.current) {
        case MapStates.DEFAULT:
          //Make polygon zone editable
          if (selectedZoneRef.current) {
            selectedZoneRef.current.setEditable(false);
          }
          deselectAllPolygons();
          selectPolygon(polygon);
          break;

        case MapStates.DELETE:
          // Delete polygon
          console.log('Delete polygon', polygon);
          setPolygons(prevVal => {
            const present = prevVal.filter(
              poly => poly.id !== currentPolygonData.id
            );
            historySetPresent(present);
            return present;
          });
          polygon.setMap(null);
          removePolygon(polygon, polygonsRef.current);
          break;
      }
    });

    addPolygonUpdateListeners(polygon.getPath(), polygonData);
  };

  const addPolygonUpdateListeners = (path, data) => {
    // clear previous listeners
    google.maps.event.clearListeners(path);
    //On update path point
    google.maps.event.addListener(path, 'set_at', () => {
      updatePolygonPath(data);
    });
    //On add new point
    google.maps.event.addListener(path, 'insert_at', () => {
      updatePolygonPath(data);
    });
  };

  const updatePolygonPath = polygonData => {
    const coordinates = polygonData.polygon
      .getPath()
      .getArray()
      .map(c => c.toJSON());
    const overlapping = hasOverlappingLines(coordinates);
    console.log('Overlapping', { overlapping });
    console.log('DrawingManager updatePolygonPath');
    setSelectedZone(zone => ({
      ...zone,
      geometry: {
        ...zone.geometry,
        coordinates: polygonsRef.current
          .filter(polygon => polygon.id.startsWith(zone.polygonID || zone.id))
          .map(polygon => {
            return polygon.id === polygonData.polygon.id
              ? [coordinates]
              : polygon.geometry.coordinates;
          })
      }
    }));
    setPolygons(prevPolygons => {
      const present = prevPolygons.map(poly =>
        poly.id === polygonData.polygon.id
          ? {
              ...poly,
              geometry: {
                ...poly.geometry,
                coordinates: [coordinates]
              },
              overlapping
            }
          : poly
      );
      historySetPresent(present);
      return present;
    });
  };

  const deselectAllPolygons = () => {
    polygons.forEach(polygonData => {
      if (polygonData.polygon) {
        polygonData.polygon.setEditable(false);
        polygonData.polygon.setDraggable(false);
      }
    });
    selectedZoneRef.current = null;
  };

  const selectPolygon = polygon => {
    polygon.setEditable(true);
    //polygon.setDraggable(true);
    selectedZoneRef.current = polygon;
  };

  const setSelectedDrawingZone = zone => {
    console.log('Drawing select', zone);
    selectedZone.current = zone;
  };

  return {
    deselectAllPolygons,
    createPolygonData,
    setSelectedZone: setSelectedDrawingZone
  };
}
