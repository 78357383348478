import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MapContext } from '../providers/mapProvider';
import { useTranslation } from 'react-i18next';
import ShipmentArea from './ShipmentArea';
import { CreateZoneModes } from '../dataGLS/CloserModes';
import EditZone from './EditZone';
import CloserModal from './CloserModal';
import { ZoneMovements, ZoneStatusCodes } from '../dataGLS/zoneData';
import moment from 'moment/moment';

const Zones = () => {
  const {
    createZone,
    zones,
    zonesBackup,
    polygons,
    polygonsBackup,
    createZoneMode,
    selectedZone,
    deleteZone,
    updatePriorityZone,
    setUpdatingZones,
    updateStatusZone,
    setSelectedZone,
    setCreateZoneMode,
    centerZone,
    history,
    drivers
  } = useContext(MapContext);
  const { t } = useTranslation();

  const [modal, setModal] = useState({});

  const smallColumn = () => {
    return createZoneMode !== CreateZoneModes.DISABLED;
  };

  const showModalDisableZone = zone => {
    console.log('disableAction');
    if (zone.status.code === ZoneStatusCodes.ENABLED) {
      setModal({
        header: t('Disable zone'),
        body: <p>{t('Disable zone warning')}</p>,
        buttonLabel: t('Disable zone'),
        show: true,
        callback: () => {
          console.log('Press disable on zone', zone);
          zone.status = {
            code: ZoneStatusCodes.DISABLED,
            at: moment().format('YYYY-MM-DDTHH:mm:ssZ')
          };
          updateStatusZone(zone);
        }
      });
    } else {
      setModal({
        header: t('Enable zone'),
        body: <p>{t('Enable zone warning')}</p>,
        buttonLabel: t('Enable zone'),
        show: true,
        callback: () => {
          console.log('Press disable on zone', zone);
          zone.status = {
            code: ZoneStatusCodes.ENABLED
          };
          updateStatusZone(zone);
        }
      });
    }
  };
  const showModalDeleteZone = zone => {
    console.log('deleteAction');
    let driver = t('Not assigned');
    if (zone.driver) {
      const driverAssigned = drivers.find(d => d.code === zone.driver.code);
      if (driverAssigned) {
        driver = driverAssigned.name;
      }
    }
    setModal({
      header: t('Delete zone'),
      body: (
        <>
          <p>{t('Delete zone warning')}</p>
          <p className={'mb-0'}>
            {t('Name')}: <strong>{zone.name}</strong>
          </p>
          <p className={'mb-0'}>
            {t('Code')}: <strong>{zone.code}</strong>
          </p>
          <p className={'mb-0'}>
            {t('Driver')}: <strong>{driver}</strong>
          </p>
        </>
      ),
      buttonLabel: t('Delete'),
      show: true,
      callback: () => {
        console.log('Press delete on zone', zone);
        deleteZone(zone);
      },
      buttonVariant: 'danger',
      buttonIcon: 'delete'
    });
  };

  const editAction = zone => {
    setSelectedZone(zone);
    centerZone(zone);
    zonesBackup.current = zones;
    polygonsBackup.current = polygons;
    setCreateZoneMode(CreateZoneModes.DATA);
    history.current = {
      past: [],
      present: polygons,
      future: []
    };
    console.log('History Edit', history.current);
  };

  const moveAction = (zone, index, movement) => {
    console.log("Move zone", zone);
    switch (movement) {
      case ZoneMovements.UP:
        zone.priority = (zone.priority || 1) + 1;
        break;
      case ZoneMovements.DOWN:
        if (zone.priority === 1) return;
        zone.priority = (zone.priority || 1) - 1;
        break;
      case ZoneMovements.TOP:
        zone.priority = Math.max(...zones.map(z => z.priority || 1)) + 1;
        break;
      case ZoneMovements.BOTTOM:
        zone.priority = Math.min(...zones.map(z => z.priority || 1)) - 1;
        if (zone.priority < 1) {
          zone.priority = 1;
        }
        break;
    }
    //setZonesAssign(reorderZones);
    setUpdatingZones(true);
    updatePriorityZone(zone);
  };

  return (
    <div className={`d-flex`}>
      <div
        className={`flex-col zones-col-inner ${
          smallColumn() ? 'small border-end' : ' flex-1'
        }`}
        style={{ minWidth: '100px' }}
      >
        <div className={`${!smallColumn() ? 'd-flex' : ''} p-3 `}>
          <div className={`${!smallColumn() ? 'd-flex' : ''} `}>
            <p className={`mb-0 lh-1 ${smallColumn() ? 'text-center' : ''}`}>
              <span className="material-symbols-outlined">stacks</span>
            </p>
            {!smallColumn() && (
              <div>
                <p className={'mb-0 ms-2 fw-bold'}>{t('Delivery areas')}</p>
                <p className={'mb-0 ms-2 fs-10'}>
                  {t('Total')}: {zones.length} {t('zones')}
                </p>
              </div>
            )}
          </div>
          {!smallColumn() && (
            <>
              <Button
                className={'ms-auto btn-cta rounded-pill'}
                onClick={createZone}
              >
                {t('Add zone')}
              </Button>
            </>
          )}
        </div>
        <div
          className={'overflow-y-auto'}
          style={{
            height: `calc(100vh - ${smallColumn() ? '134px' : '154px'})`
          }}
        >
          {zones.map((zone, index) => {
            return (
              <ShipmentArea
                zone={zone}
                key={zone.id}
                index={index + 1}
                disableAction={showModalDisableZone}
                deleteAction={showModalDeleteZone}
                editAction={editAction}
                moveAction={moveAction}
              />
            );
          })}
          <ShipmentArea />
        </div>
      </div>
      {selectedZone && <EditZone setModal={setModal} />}
      <CloserModal
        header={modal.header}
        body={modal.body}
        buttonLabel={modal.buttonLabel}
        callback={modal.callback}
        show={modal.show}
        showCancelButton={modal.showCancelButton}
        buttonVariant={modal.buttonVariant}
        buttonIcon={modal.buttonIcon}
        setShow={val => {
          setModal(prevModal => {
            return { ...prevModal, show: val };
          });
        }}
      />
    </div>
  );
};

export default Zones;
