import { useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StopMarker from './StopMarker';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ClusterRenderer } from './ClusterRenderer';

const StopsMarkers = memo(props => {
  const { stops } = props;
  const [markers, setMarkers] = useState({});
  const map = useMap();

  // Memoize clusterer configuration
  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({
      map,
      renderer: new ClusterRenderer(),
      maxZoom: 15,
      gridSize: 50
    });
  }, [map]);

  // Optimize clusterer update
  useEffect(() => {
    if (!clusterer) return;

    const markerArray = Object.values(markers);
    if (markerArray.length === 0) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(markerArray);

    return () => clusterer.clearMarkers();
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(prevMarkers => {
      if ((marker && prevMarkers[key]) || (!marker && !prevMarkers[key])) {
        return prevMarkers;
      }

      if (marker) {
        return { ...prevMarkers, [key]: marker };
      }

      const { [key]: _, ...newMarkers } = prevMarkers;
      return newMarkers;
    });
  }, []);

  // Memoize stops rendering
  const renderedStops = useMemo(
    () =>
      stops.map(stop => (
        <StopMarker key={stop.id} stop={stop} setMarkerRef={setMarkerRef} />
      )),
    [stops, setMarkerRef]
  );

  return <>{renderedStops}</>;
});

StopsMarkers.propTypes = {
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired
};

export default withTranslation()(StopsMarkers);
