import axios from "axios";
import {getItemFromStore} from '../utils';
// default
axios.defaults.baseURL = process.env.REACT_APP_API_HOST || '';
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Accept-language'] = "es";
const setAuth = (token) => {
  axios.defaults.headers.common['x-access-token'] = token;
}

// content type
const token = getItemFromStore('token');
if (token) {
  setAuth(token);
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    //return response.data ? response.data : response;
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message = "Can't connect to server";
    if(error.response) {
      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          /*if(User.isLogged()){
            window.location = "/logout";
          }*/
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.message || error;
      }
      return Promise.reject(error.response);
    }
    return Promise.reject({data:{}, message: "Internal Server Error"});
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token='') => {
  setAuth(token);
};

class APIClient {
  /**
   * Fetches data from given url
   */

    //  get = (url, params) => {
    //   return axios.get(url, params);
    // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * post given data to url
   */
  post = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, data) => {
    return axios.delete(url, { data });
  };
}
const getLoggedinUser = () => {
  /*const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }*/
  return {};
};

export { APIClient, setAuthorization, getLoggedinUser };