import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Avatar from '../components/common/Avatar';
import CounterZone from './CounterZone';
import { Collapse, ProgressBar, Dropdown, Button } from 'react-bootstrap';
import { getFPEType } from '../helpers/utilsECC';
import { CreateZoneModes, MapStates } from '../dataGLS/CloserModes';
import { resetZoneData, zoneCalcData } from '../helpers/zoneCalc';
import { MapContext } from '../providers/mapProvider';
import { polygonOptions } from '../dataGLS/polygon';
import {
  ZoneDefaultID,
  ZoneMovements,
  ZoneStatusCodes,
  zoneTypes
} from '../dataGLS/zoneData';
import PropTypes from 'prop-types';
import FilterLabel from './FilterLabel';
import { debounce } from 'lodash';
import {hideStops} from '../helpers/utils';

const ShipmentArea = React.memo(
  props => {
    const {
      t,
      zone,
      index,
      disableAction,
      deleteAction,
      editAction,
      moveAction
    } = props;
    const {
      shipmentAreas,
      zones,
      setZones,
      setZonesPrint,
      createZoneMode,
      polygons,
      setPolygons,
      setMapState,
      dateRanges,
      services
    } = useContext(MapContext);
    const { centerZone, drivers } = useContext(MapContext);
    const [expanded, setExpanded] = useState(false);
    const [data, setData] = useState({ stopsThresholds: ['', ''] });

    const getData = useCallback(
      debounce(() => {
        let newData = resetZoneData({
          loadColor: '',
          loadText: '',
          loadTextMin: '',
          stopsNumber: '',
          packages: '',
          volume: '',
          FPEPassed: '',
          FPEToday: '',
          FPEFuture: ''
        });

        if (zone) {
          newData = zoneCalcData(zone);
          newData.assignedTo = zone.driver
            ? drivers.find(driver => driver.code === zone.driver.code)?.name ||
              t('Not assigned')
            : t('Not assigned');
          if (polygons) {
            const polygonIndexes = polygons.reduce((list, polygon, index) => {
              if (polygon.id.startsWith(zone.polygonID || zone.id))
                list.push(index);
              return list;
            }, []);
            if (polygonIndexes.length > 0) {
              if (zone.id === ZoneDefaultID) {
                newData.loadColor = 'success';
                newData.loadText = t('OK');
                newData.loadTextMin = t('OK');
              } else if (newData.stopsNumber < newData.stopsThresholds[0]) {
                newData.loadText = `${t('Undercapacity')} (< ${
                  newData.stopsThresholds[0]
                })`;
                newData.loadTextMin = `< ${newData.stopsThresholds[0]}`;
              } else if (newData.stopsNumber > newData.stopsThresholds[1]) {
                newData.loadText = `${t('Overcapacity')} (> ${
                  newData.stopsThresholds[1]
                })`;
                newData.loadTextMin = `> ${newData.stopsThresholds[1]}`;
              } else {
                newData.loadText = t('OK');
                newData.loadTextMin = t('OK');
              }
            }
          }
        } else if (shipmentAreas) {
          const cps = shipmentAreas.map(area => {
            return area.code;
          });
          newData.assignedTo = (
            <>
              {t('ZIP')}: <span className={'fw-bold'}>{cps.join(', ')}</span>
            </>
          );
          if (shipmentAreas.length > 0 && shipmentAreas[0].stops) {
            newData.stopsNumber = shipmentAreas[0].stops.length;
            shipmentAreas[0].stops.forEach(stop => {
              newData.packages += stop.packageCount || 0;
              newData.weight += stop.weight || 0;
              switch (getFPEType(stop.estimatedDeliveryDate)) {
                case -1:
                  newData.FPEPassed += 1;
                  break;
                case 0:
                  newData.FPEToday += 1;
                  break;
                case 1:
                  newData.FPEFuture += 1;
                  break;
              }
            });
          }
        }
        setData({ ...newData });
      }, 300), // 300 ms de espera
      [zone, shipmentAreas, drivers, polygons]
    );

    const selectPolygon = () => {
      setPolygons(prevPolygons =>
        prevPolygons.map(polygon => {
          if (polygon.id.startsWith(zone.polygonID || zone.id) && expanded) {
            polygon.polygon.setOptions({
              strokeWeight: 4,
              fillOpacity: 0.5
            });
          } else {
            polygon.polygon.setOptions({
              strokeWeight: polygonOptions.strokeWeight,
              fillOpacity: polygonOptions.fillOpacity
            });
          }
          return polygon;
        })
      );
    };

    useEffect(() => {
      getData();
    }, [zone, shipmentAreas]);
    /*
    useEffect(() => {
        if (!!zone && expanded) {
          centerZone(zone);
        }
        if (zone) selectPolygon();
      }, [expanded]);
    */
    const toggleShow = useCallback(() => {
      zone.show = !zone.show;
      setZonesPrint(zones.map(z => (z.id === zone.id ? zone : z)));
    }, [zone, zones]);

    const toggleOpen = () => {
      zone.open = !zone.open;
      if (zone.open) {
        centerZone(zone);
        setMapState(MapStates.DEFAULT);
      }
      setZones(prevZones => prevZones.map(z => (z.id === zone.id ? zone : z)));
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <span className="material-symbols-outlined">more_vert</span>
      </a>
    ));

    switch (createZoneMode) {
      case CreateZoneModes.MAP:
      case CreateZoneModes.DATA:
        return (
          <div
            className={`p-2 text-center position-relative mb-1 ms-1 ${
              zone && zone.type === zoneTypes.TEMPORARY
                ? 'bg-primary-10 border border-primary rounded'
                : ''
            }`}
          >
            <div className={'position-relative m-auto d-inline-block'}>
              <Avatar
                name={
                  index === undefined
                    ? t('Shipment Area avatar')
                    : `${zone.priority || 1}`
                }
                isExact={index !== undefined}
                mediaClass={
                  'bg-surface-light-2 text-dark fw-normal border border-secondary'
                }
                className={'fs-6'}
                style={{
                  width: '3rem',
                  height: '3rem'
                }}
              />
              {zone && zone.type === zoneTypes.TEMPORARY && (
                <span
                  className="text-primary bg-white rounded-circle material-symbols-outlined position-absolute fs-9"
                  style={{ right: '0px', top: '0px' }}
                >
                  schedule
                </span>
              )}
            </div>
            <div className={'px-0'}>
              <p
                className={'m-0 fw-bold fs-10 word-wrap'}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {zone ? zone.name : t('Shipment Area')}
              </p>
            </div>
            {zone && !hideStops && (
              <div className={'px-1'}>
                <p className={`m-0 fw-bold fs-10 text-${zone.loadColor}`}>
                  {' '}
                  <span
                    className={`rounded-circle d-inline-block bg-${zone.loadColor}`}
                    style={{ width: 10, height: 10 }}
                  ></span>{' '}
                  {data.loadTextMin}
                </p>
              </div>
            )}
          </div>
        );
      case CreateZoneModes.DISABLED:
      default:
        return (
          <div
            className={`mb-1 ms-1 ${
              zone && zone.type === zoneTypes.TEMPORARY
                ? 'bg-primary-10 border border-primary rounded'
                : ''
            }`}
          >
            <div className={'p-2 d-flex align-items-start '}>
              <div className={'position-relative'}>
                <Avatar
                  name={
                    index === undefined
                      ? t('Shipment Area avatar')
                      : `${zone.priority || 1}`
                  }
                  mediaClass={
                    'bg-surface-light-2 text-dark fw-normal border border-secondary'
                  }
                  isExact={index !== undefined}
                  className={'fs-6'}
                  style={{
                    width: '3rem',
                    height: '3rem'
                  }}
                />
                {zone && zone.type === zoneTypes.TEMPORARY && (
                  <span
                    className="text-primary bg-white rounded-circle material-symbols-outlined position-absolute fs-9"
                    style={{ right: '0px', top: '0px' }}
                  >
                    schedule
                  </span>
                )}
              </div>
              <div className={'px-2'}>
                <p className={'m-0 fw-bold fs-10'}>
                  {zone ? zone.name : t('Shipment Area')}
                </p>
                {zone && <p className={'m-0 fs-10 fst-italic'}>{zone.code}</p>}
                <p className={`m-0 fs-10 ${zone ? 'text-secondary' : ''}`}>
                  {data.assignedTo}
                </p>
              </div>

              {zone && (
                <>
                  <Button
                    className={'ms-auto rounded-pill lh-1'}
                    variant={zone.show ? 'outline-primary' : 'primary'}
                    onClick={toggleShow}
                  >
                    <span className="material-symbols-outlined fs-9">
                      {zone.show ? 'visibility' : 'visibility_off'}
                    </span>
                  </Button>
                  <Button
                    className={'rounded-pill lh-1 ms-1'}
                    variant={'outline-primary'}
                    onClick={() => { centerZone(zone) }}
                  >
                    <span className="material-symbols-outlined fs-9">
                      zoom_in
                    </span>
                  </Button>
                  <Dropdown align="end">
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu className={'py-0'}>
                      <Dropdown.Item
                        onClick={() => editAction(zone)}
                        className={'py-2 text-dark'}
                      >
                        <span className="material-symbols-outlined me-2">
                          edit
                        </span>{' '}
                        {t('Edit')}
                      </Dropdown.Item>
                      {false && (
                        <Dropdown.Item
                          onClick={() => disableAction(zone)}
                          className={'py-2 text-dark'}
                        >
                          <span className="material-symbols-outlined me-2">
                            {zone.status.code === ZoneStatusCodes.ENABLED
                              ? 'layers_clear'
                              : 'layers'}
                          </span>{' '}
                          {zone.status.code === ZoneStatusCodes.ENABLED
                            ? t('Disable zone')
                            : t('Enable zone')}
                        </Dropdown.Item>
                      )}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          moveAction(zone, index, ZoneMovements.TOP);
                        }}
                        className={'py-2 text-dark'}
                      >
                        <span className="material-symbols-outlined me-2">
                          keyboard_double_arrow_up
                        </span>{' '}
                        {t('To top')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          moveAction(zone, index, ZoneMovements.UP);
                        }}
                        className={'py-2 text-dark'}
                      >
                        <span className="material-symbols-outlined me-2">
                          keyboard_arrow_up
                        </span>{' '}
                        {t('Up one position')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          moveAction(zone, index, ZoneMovements.DOWN);
                        }}
                        className={'py-2 text-dark'}
                      >
                        <span className="material-symbols-outlined me-2">
                          keyboard_arrow_down
                        </span>{' '}
                        {t('Down one position')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          moveAction(zone, index, ZoneMovements.BOTTOM);
                        }}
                        className={'py-2 text-dark'}
                      >
                        <span className="material-symbols-outlined me-2">
                          keyboard_double_arrow_down
                        </span>{' '}
                        {t('To bottom')}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          deleteAction(zone);
                        }}
                        className={'text-danger py-2'}
                      >
                        <span className="material-symbols-outlined me-2">
                          delete
                        </span>{' '}
                        {t('Delete')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
            {zone && (
              <>
                <div className={'mx-2'}>
                  <p>
                    {zone.services?.map((service, index) => {
                      return (
                        <FilterLabel
                          key={index}
                          icon={'package_2'}
                          label={services.find(serv => serv.code === service).name}
                        />
                      );
                    })}
                    {zone.postalCodes?.map((postalCode, index) => {
                      return (
                        <FilterLabel
                          key={index}
                          icon={'location_on'}
                          label={postalCode.code}
                        />
                      );
                    })}
                    {zone.dateRange && (
                      <FilterLabel
                        icon={'date_range'}
                        label={
                          dateRanges.find(dr => dr.code === zone.dateRange)?.name
                        }
                      />
                    )}
                    {/* Filter not active */}
                    {zone.clients?.map((postalCode, index) => {
                      return (
                        <FilterLabel
                          key={index}
                          icon={'work'}
                          label={postalCode.code}
                        />
                      );
                    })}
                  </p>
                </div>
                {!hideStops &&
                  <div className={'mx-2'}>
                    <p
                      className={`m-0 fw-bold fs-10 text-end text-${zone.loadColor}`}
                    >
                      {' '}
                      <span
                        className={`rounded-circle d-inline-block bg-${zone.loadColor}`}
                        style={{width: 10, height: 10}}
                      ></span>{' '}
                      {data.loadText}
                    </p>
                    <ProgressBar
                      now={data.stopsNumber}
                      max={data.stopsThresholds[1]}
                      variant={zone.loadColor}
                      style={{height: '4px'}}
                      className="mb-3"
                    />
                  </div>
                }
              </>
            )}
            { !hideStops &&
              <>
                <Collapse in={zone ? zone.open : expanded}>
                  <div className={'p-2'}>
                    <CounterZone name={t('Shipments')} value={data.stopsNumber}/>
                    <CounterZone name={t('Package nº')} value={data.packages}/>
                    <CounterZone name={t('Weight (Kg)')} value={data.weight}/>
                    <CounterZone name={t('Volume (m3)')} value={data.volume}/>
                    <CounterZone name={t('FPE passed')} value={data.FPEPassed}/>
                    <CounterZone name={t('FPE today')} value={data.FPEToday}/>
                    <CounterZone name={t('FPE future')} value={data.FPEFuture}/>
                  </div>
                </Collapse>

                <div
                  className={`text-center cursor-pointer bg-hover-gray m-0 ${
                    zone ? 'mb-2' : ''
                  }`}
                  onClick={() => {
                    if (zone) {
                      toggleOpen();
                    } else {
                      setExpanded(!expanded);
                    }
                  }}
                >
                  <span className="material-symbols-outlined">
                    {(zone ? zone.open : expanded)
                      ? 'arrow_drop_up'
                      : 'arrow_drop_down'}
                  </span>
                </div>
              </>
            }
          </div>
        );
    }
  },
  (prevProps, nextProps) => {
    if(prevProps.zone && nextProps.zone && (prevProps.zone.code === "D10000Z17" || nextProps.zone.code === "D10000Z17") ){
      console.log("ShipmentArea ",{prevProps, nextProps, equal: JSON.stringify(prevProps.zone) === JSON.stringify(nextProps.zone)});
    }
    return prevProps.zone && nextProps.zone
      ? JSON.stringify(prevProps.zone) === JSON.stringify(nextProps.zone)
      : true;
  }
);

ShipmentArea.propTypes = {
  t: PropTypes.func,
  zone: PropTypes.any,
  index: PropTypes.any,
  shipmentAreas: PropTypes.array,
  polygons: PropTypes.array,
  setPolygons: PropTypes.func,
  createZoneMode: PropTypes.string,
  disableAction: PropTypes.func,
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
  moveAction: PropTypes.func
};
export default withTranslation()(ShipmentArea);
