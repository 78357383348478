import { getFPEType } from './utilsECC';
import { GeometryTypes } from '../dataGLS/polygon';
import {
  coordinatesArrayToObject,
  coordinatesObjectToArray
} from './polygonUtils';

const zoneDataCache = new Map();

export const resetZoneData = newData => {
  newData.stopsNumber = 0;
  newData.packages = 0;
  newData.volume = 0;
  newData.weight = 0;
  newData.FPEPassed = 0;
  newData.FPEToday = 0;
  newData.FPEFuture = 0;
  newData.assignedTo = '';
  return newData;
};

export const zoneCalcData = zone => {
  const cacheKey = `${zone?.id}-${
    zone?.stops?.length
  }-${zone?.thresholds?.stops?.join('-')}`;
  if (zoneDataCache.has(cacheKey)) {
    return zoneDataCache.get(cacheKey);
  }

  const newData = {
    loadColor: '',
    loadText: '',
    loadTextMin: '',
    stopsNumber: 0,
    packages: 0,
    volume: 0,
    weight: 0,
    FPEPassed: 0,
    FPEToday: 0,
    FPEFuture: 0,
    name: '',
    type: '',
    stopsThresholds: ['', ''],
    assignedTo: ''
  };

  if (!zone) {
    return newData;
  }

  newData.stopsThresholds = zone.thresholds?.stops || ['', ''];

  if (zone.stops?.length) {
    newData.stopsNumber = zone.stops.length;
    zone.stops.forEach(stop => {
      newData.packages += stop.packageCount || 0;
      newData.weight += stop.weight || 0;
      newData.volume += stop.size || 0;
      const fpeType = getFPEType(stop.estimatedDeliveryDate);
      switch (fpeType) {
        case -1:
          newData.FPEPassed++;
          break;
        case 0:
          newData.FPEToday++;
          break;
        case 1:
          newData.FPEFuture++;
          break;
      }
    });
  }

  zoneDataCache.set(cacheKey, newData);
  return newData;
};

export const coordinatesZoneObjectToArray = zone => {
  if (!zone.geometry || !zone.geometry.coordinates) return zone;
  const coordinates = coordinatesObjectToArray(zone, 'geometry');
  return {
    ...zone,
    geometry: {
      ...zone.geometry,
      coordinates
    }
  };
};

export const coordinatesZoneArrayToObject = zone => {
  if (!zone.geometry || !zone.geometry.coordinates)
    return {
      ...zone,
      show: true,
      open: false
    };
  const coordinates = coordinatesArrayToObject(zone, 'geometry');
  const type = GeometryTypes.MultiPolygon;
  return {
    ...zone,
    geometry: {
      ...zone.geometry,
      type,
      coordinates
    },
    show: true,
    open: false
  };
};
