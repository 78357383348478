import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
  lazy,
  memo
} from 'react';
import {
  driversFindAll,
  getStopsDateRanges,
  getStopsPostalCodes,
  getStopsServices,
  postalCodesFinsAll,
  stopsFindAll,
  zonesCreate,
  zonesDelete,
  zonesFindAll,
  zonesUpdate
} from '../helpers/API/closerAPI';
import { AuthContext } from './AuthProvider';
import { CreateZoneModes, MapStates } from '../dataGLS/CloserModes';
import {
  DefaultZoneData,
  ZoneDefaultID,
  zonesSort,
  zoneTypes
} from '../dataGLS/zoneData';
import {
  GeometryTypes,
  polygonOptions,
  PolygonTypes
} from '../dataGLS/polygon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Closer } from '@gls_spain/closer-common';
import { coordinatesZoneObjectToArray } from '../helpers/zoneCalc';

const LoadingScreen = lazy(() => import('../componentsGLS/LoadingScreen'));

const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const MemoizedMapProvider = ({ children, t }) => {
  const google = window.google;
  const [createZoneMode, setCreateZoneMode] = useState(
    CreateZoneModes.DISABLED
  );
  const [triggerClearZone, setTriggerClearZone] = useState(false);

  // To get a correct workflow we create zone "stages"
  // 1- To assign. Empty by default, set to assign. Once assigned set to next stage and clear it
  // 2- To print. Empty by default, set to print polygons in map. Once printed, set to next stage and clear it
  // 3- To show. Always has the current zones.
  const [zonesAssign, setZonesAssign] = useState([]);
  const [zonesPrint, setZonesPrint] = useState([]);
  const [zones, setZones] = useState([]);
  const zonesBackup = useRef(zones);
  const [shipmentAreas, setShipmentAreas] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [filterPostalCodes, setFilterPostalCodes] = useState([]);
  const [filterDateRanges, setFilterDateRanges] = useState('');
  const [showStops, setShowStops] = useState(true);
  const [stops, setStops] = useState([]);
  const stopsFiltered = useMemo(() => {
    if (!stops) return null;
    if (!showStops) return [];
    return stops.filter(stop => {
      let show = true;
      if (filterServices.length > 0) {
        show =
          show &&
          !!filterServices.find(service => service.value === stop.service);
      }
      if (show && filterPostalCodes.length > 0) {
        show =
          show &&
          !!filterPostalCodes.find(
            postalCode => postalCode.value === stop.address.postalCode
          );
      }
      if (show && filterDateRanges && filterDateRanges?.range.length === 2) {
        const fpe = new Date(stop.estimatedDeliveryDate);
        show =
          show &&
          filterDateRanges.range[0] <= fpe &&
          filterDateRanges.range[1] >= fpe;
        /*!!filterDateRanges.find(
          ({ range }) => range[0] <= fpe && range[1] >= fpe
        );*/
      }
      return show;
    });
  }, [stops, filterServices, filterPostalCodes, filterDateRanges, showStops]);
  const [centerArea, setCenterArea] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const { date } = useContext(AuthContext);

  const [areaDownloaded, setAreaDownloaded] = useState(false);
  const [zonesDownloaded, setZonesDownloaded] = useState(false);
  const [stopsDownloaded, setStopsDownloaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [polygons, setPolygons] = useState([]);
  const polygonsBackup = useRef();
  const [zonesPrinted, setZonesPrinted] = useState(false);
  const [stopsAssigned, setStopsAssigned] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [canChangeToDataMap, setCanChangeToDataMap] = useState(false);
  const [triggerCancelEditZone, setTriggerCancelEditZone] = useState(false);
  const [mapState, setMapState] = useState(MapStates.DEFAULT);
  const [drivers, setDrivers] = useState([]);
  const [services, setServices] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);
  const [updatingZones, setUpdatingZones] = useState(false);
  const history = useRef({
    past: [],
    present: [],
    future: []
  });
  const updateData = async () => {
    console.log('Update data');
    clearZones();
    setZonesPrinted(false);
    /***************************************
     Set assigndet zones by default
     change behaviour to speed up app
     MANUAL ASSIGN
     ***************************************/
    //setStopsAssigned(false);
    //setStopsDownloaded(false);
    //setZonesDownloaded(false);
    //await getStops();
    //void getZones();
    setStopsDownloaded(true);
    setStopsAssigned(false);
    setZonesDownloaded(false);
    void getZones();
  };

  /***************************************
   New function to set manual assign
   change behaviour to speed up app
   MANUAL ASSIGN
   ***************************************/
  const manualAssign = async () => {
    setLoading(true);
    setStopsAssigned(false);
    if (stops.length === 0) {
      await getStops();
    }
    setZonesAssign(zones);
  };

  useEffect(() => {
    console.log('New date', date);
    if (shipmentAreas.length === 0) return;
    console.log('New date updateData', date);
    void updateData();
  }, [date]);

  useEffect(() => {
    if (shipmentAreas.length === 0 || zonesPrinted) return;
    console.log('Updated shipmentAreas updateData');
    void updateData();
  }, [shipmentAreas]);

  useEffect(() => {
    if (shipmentAreas.length === 0) return;
    console.log('Assign Stops updatezones assign', { zonesAssign });
    assignStops();
  }, [zonesAssign]);

  const createZone = useCallback(() => {
    console.log('Create polygon');
    zonesBackup.current = zones;
    polygonsBackup.current = polygons;
    addZone({
      ...DefaultZoneData,
      polygonID: `${ZoneDefaultID}-${new Date().getTime()}`,
      priority: Math.max(...zones.map(z => z.priority || 1), 0) + 1
    });
    setCreateZoneMode(CreateZoneModes.MAP);
    history.current = {
      past: [],
      present: [],
      future: []
    };
  }, [zones, polygons]);

  const saveZone = useCallback(
    zone => {
      if (!zone) return;
      console.log('Save zone', zone);
      setUpdatingZones(true);
      if (zone.id === ZoneDefaultID) {
        void sendNewZone(zone);
      } else {
        void updateZone(zone);
      }
    },
    [filterServices, filterPostalCodes, filterDateRanges, zones]
  );

  const sendNewZone = async zone => {
    const data = {
      type: zone.type,
      name: zone.name,
      info: zone.info,
      status: zone.status,
      geometry: zone.geometry,
      thresholds: zone.thresholds,
      driver: zone.driver,
      services: zone.services,
      postalCodes: zone.postalCodes,
      priority: zone.priority
    };
    if (zone.dateRange) {
      data.dateRange = zone.dateRange;
    }
    if (zone.type === zoneTypes.TEMPORARY) {
      data['temporary'] = {
        dateRange: zone.temporary.dateRange
      };
    }
    console.log('Send new zone', { zone, data });

    try {
      const response = await zonesCreate(data);
      const newZones = zones.map(z => {
        return z.id === ZoneDefaultID
          ? { ...response, polygonID: zone.polygonID }
          : z;
      });
      console.log('NewZones set zones', newZones);
      setSelectedZone(null);
      resetFilters();
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
      /***************************************
       Auto assign zones on save new zone
       change behaviour to speed up app
       MANUAL ASSIGN
       ***************************************/
      //setStopsAssigned(false);
      //setZonesAssign(newZones);
      setStopsAssigned(true);
      setZonesPrint(newZones);
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };

  const updateZone = async zone => {
    console.log('Update zone', { selectedZone, zone });
    try {
      await zonesUpdate(zone.id, zone);
      console.log('Set zone on update zone');
      setSelectedZone(null);
      resetFilters();
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
      /***************************************
       Auto assign zones on update zone
       change behaviour to speed up app
       MANUAL ASSIGN
       ***************************************/
      setStopsAssigned(true);
      //setZonesAssign(zones.map(z => (z.id === zone.id ? zone : z)));
      setZonesPrint(zones.map(z => (z.id === zone.id ? zone : z)));
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };

  const updatePriorityZone = async zone => {
    console.log('Update zone', { selectedZone, zone });
    try {
      await zonesUpdate(zone.id, zone, ['priority']);
      console.log('Set zone on update zone');
      /***************************************
       Auto assign zones on update priority zone
       change behaviour to speed up app
       MANUAL ASSIGN
       ***************************************/
      //setZonesAssign(zones.map(z => (z.id === zone.id ? zone : z)));
      setStopsAssigned(true);
      setZones(zones.map(z => (z.id === zone.id ? zone : z)).sort(zonesSort));
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };
  const updateStatusZone = useCallback(async zone => {
    console.log('Update zone', zone);
    try {
      /***************************************
       Auto assign zones after update status zone
       change behaviour to speed up app
       MANUAL ASSIGN
       ***************************************/
      //setStopsAssigned(false);
      await zonesUpdate(zone.id, zone);
      console.log('MapProvider updateStatus setZones');
      // setZonesAssign(
      //   zones.filter(z => (z.id === zone.id ? zone : z))
      // );
      setZones(zones.filter(z => (z.id === zone.id ? zone : z)));
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const clearZones = useCallback(() => {
    console.log('set zones empty Clear polygon');
    setTriggerClearZone(val => !val);
    console.log('MapProvider clearzones setZones');
    setZones([]);
  }, []);

  const getCenterBounds = bounds => {
    return {
      lat: (bounds.south + bounds.north) / 2,
      lng: (bounds.east + bounds.west) / 2
    };
  };

  const latLngBounds = areas => {
    if (areas.length === 0) return { lat: 41.85, lng: -87.65 };
    let minLat = areas[0].centroid.coordinates.lat;
    let minLng = areas[0].centroid.coordinates.lng;
    let maxLat = areas[0].centroid.coordinates.lat;
    let maxLng = areas[0].centroid.coordinates.lng;
    areas.forEach(area => {
      if (area.centroid.coordinates.lat < minLat)
        minLat = area.centroid.coordinates.lat;
      if (area.centroid.coordinates.lng < minLng)
        minLng = area.centroid.coordinates.lng;
      if (area.centroid.coordinates.lat > maxLat)
        maxLat = area.centroid.coordinates.lat;
      if (area.centroid.coordinates.lng > maxLng)
        maxLng = area.centroid.coordinates.lng;
    });

    return {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng
    };
  };

  // Memoizar el cálculo de bounds para áreas
  const getLatLngBounds = useCallback(coordinates => {
    let minLat = coordinates[0].lat;
    let minLng = coordinates[0].lng;
    let maxLat = coordinates[0].lat;
    let maxLng = coordinates[0].lng;

    coordinates.forEach(coordinate => {
      if (coordinate.lat < minLat) minLat = coordinate.lat;
      if (coordinate.lng < minLng) minLng = coordinate.lng;
      if (coordinate.lat > maxLat) maxLat = coordinate.lat;
      if (coordinate.lng > maxLng) maxLng = coordinate.lng;
    });

    return { minLat, minLng, maxLat, maxLng };
  }, []);

  // Memoizar el cálculo de bounds para zonas
  const latLngBoundsZone = useCallback(zone => {
    let bounds = { minLat: 0, minLng: 0, maxLat: 0, maxLng: 0 };

    switch (zone.geometry.type) {
      case GeometryTypes.Polygon:
        bounds = getLatLngBounds(zone.geometry.coordinates[0]);
        break;
      case GeometryTypes.MultiPolygon:
        zone.geometry.coordinates.forEach((polygon, index) => {
          const polygonBounds = getLatLngBounds(polygon[0]);
          if (index === 0) {
            bounds = polygonBounds;
          } else {
            bounds.minLat = Math.min(bounds.minLat, polygonBounds.minLat);
            bounds.minLng = Math.min(bounds.minLng, polygonBounds.minLng);
            bounds.maxLat = Math.max(bounds.maxLat, polygonBounds.maxLat);
            bounds.maxLng = Math.max(bounds.maxLng, polygonBounds.maxLng);
          }
        });
        break;
    }

    return {
      north: bounds.maxLat,
      south: bounds.minLat,
      east: bounds.maxLng,
      west: bounds.minLng
    };
  }, []);

  const getShipmentAreas = async () => {
    try {
      const postalCodes = await postalCodesFinsAll();
      console.log('postalCodes', postalCodes);
      setShipmentAreas(postalCodes);
      const bounds = latLngBounds(postalCodes);
      setCenterArea(getCenterBounds(bounds));
      setMapBounds(bounds);
    } catch (e) {
      console.error(e);
    }
    setAreaDownloaded(true);
  };

  const getDrivers = async () => {
    try {
      const d = await driversFindAll();
      console.log('All drivers', d);
      setDrivers(d);
    } catch (e) {
      console.error(e);
    }
  };
  const getServices = async () => {
    try {
      const s = await getStopsServices();
      console.log('All services', s);
      setServices(s);
    } catch (e) {
      console.error(e);
    }
  };
  const getPostalCodes = async () => {
    try {
      const pc = await getStopsPostalCodes();
      console.log('All Postal Codes Filter', pc);
      setPostalCodes(pc);
    } catch (e) {
      console.error(e);
    }
  };
  const getDateRanges = async () => {
    try {
      const dr = await getStopsDateRanges();
      console.log('Date Ranges', dr);
      setDateRanges(dr);
    } catch (e) {
      console.error(e);
    }
  };

  const centerZone = useCallback(zone => {
    console.log('Center to zone', zone);
    if (!zone.geometry || !zone.geometry.coordinates) return;
    const bounds = latLngBoundsZone(zone);
    setMapBounds(bounds);
  }, []);

  const getZones = async () => {
    try {
      const zones = await zonesFindAll();
      if (zones.length === 0) {
        setStopsAssigned(true);
        setZonesPrinted(true);
        setZones(zones);
      } else {
        setZonesPrint(zones);
      }
    } catch (e) {
      console.error(e);
    }
    setZonesDownloaded(true);
  };
  useEffect(() => {
    console.log('mapPRovider zonesPrint', zonesPrint);
  }, [zonesPrint]);
  useEffect(() => {
    console.log('mapPRovider zones', zones);
    console.log('zones assigned zone mode:', {
      createZoneMode,
      canChangeToDataMap
    });
    if (canChangeToDataMap && createZoneMode === CreateZoneModes.MAP) {
      setCreateZoneMode(CreateZoneModes.DATA);
      setCanChangeToDataMap(false);
    }
  }, [zones]);

  const getStops = async () => {
    try {
      const stops = await stopsFindAll(date);
      console.log('Stops', stops);
      setStops(stops);
    } catch (e) {
      /* empty */
    }
    setStopsDownloaded(true);
  };

  const assignStops = useCallback(() => {
    console.log('AssignStops', { zonesAssign });
    // For every stop find polygon for every zone. IF stops is inside, assign if not assigned to a zone, assign to postal code
    const assignedZones = zonesAssign.map(zone => {
      return { ...zone, stops: [] };
    });
    console.log('AssignStops', { assignedZones });
    assignedZones.sort(zonesSort);
    console.log('Sorted zones', structuredClone(assignedZones));
    console.log('Assign zones polygons', polygons);
    console.log('Assign zones polygons length', polygons.length);
    const assignedAreas = shipmentAreas.map(area => {
      return { ...area, stops: [] };
    });
    // Check for each stop on which zone assign
    // if there is no zone, assign to area
    const stopsAssigned = { shipmentAarea: [] };
    stops.forEach(stop => {
      try {
        if (stops.length > 0) {
          let assignedZones2 = zonesAssign.map(zone => {
            return {
              ...coordinatesZoneObjectToArray(structuredClone(zone)),
              stops: []
            };
          });
          const stopB = {
            ...stop,
            address: {
              ...stop.address,
              location: {
                ...stop.address.location,
                coordinates: [
                  stop.address.location.coordinates.lng,
                  stop.address.location.coordinates.lat
                ]
              }
            }
          };
          const z = Closer.getStopZones(assignedZones2, stopB);
          if (z.length > 0) {
            if (stopsAssigned[z[0].id]) {
              stopsAssigned[z[0].id].push(stop);
            } else {
              stopsAssigned[z[0].id] = [stop];
            }
          } else {
            stopsAssigned['shipmentAarea'].push(stop);
            if (assignedAreas[0].stops) {
              assignedAreas[0].stops.push(stop);
            } else {
              assignedAreas[0].stops = [stop];
            }
          }
        }
      } catch (e) {
        console.error('Error on assign library');
        console.error(e);
      }
    });
    console.log('Assigned', { stopsAssigned });
    const coloredPolygons = [...polygons];
    const polygonUpdateMap = new Map(); // Para trackear qué polígonos necesitan actualización

    assignedZones.forEach(zone => {
      zone.stops = stopsAssigned[zone.id] || [];

      // Establecer thresholds por defecto si no existen
      if (!zone.thresholds?.stops) {
        zone.thresholds = { stops: ['', ''] };
      }

      // Determinar el color basado en las paradas
      let fillColor, strokeColor, loadColor;
      if (zone.stops.length < zone.thresholds.stops[0]) {
        fillColor = strokeColor = 'blue';
        loadColor = 'primary';
      } else if (zone.stops.length > zone.thresholds.stops[1]) {
        fillColor = strokeColor = 'red';
        loadColor = 'danger';
      } else {
        fillColor = strokeColor = 'green';
        loadColor = 'success';
      }

      // Encontrar y actualizar polígonos relacionados
      coloredPolygons.forEach((polygon, index) => {
        if (polygon.id.startsWith(zone.polygonID || zone.id)) {
          polygonUpdateMap.set(index, {
            polygon,
            options: { fillColor, strokeColor }
          });
        }
      });

      zone.loadColor = loadColor;
    });

    // Aplicar actualizaciones en batch
    polygonUpdateMap.forEach(({ polygon, options }, index) => {
      polygon.polygon.setOptions(options);
      coloredPolygons[index] = polygon;
    });

    console.log('Set zones assigned', { assignedZones, coloredPolygons });
    setZonesPrint(assignedZones);
    setShipmentAreas(assignedAreas);
    setStopsAssigned(true);
    setPolygons(coloredPolygons);
  }, [zonesAssign, polygons, stops]);

  useEffect(() => {
    console.log('createZoneMode', createZoneMode);
  }, [createZoneMode]);

  useEffect(() => {
    const newV =
      !(
        areaDownloaded &&
        zonesDownloaded &&
        stopsDownloaded &&
        zonesPrinted &&
        stopsAssigned
      ) || updatingZones;
    console.log('Loading', {
      areaDownloaded,
      zonesDownloaded,
      stopsDownloaded,
      zonesPrinted,
      stopsAssigned,
      updatingZones,
      newV
    });
    setLoading(newV);
  }, [
    areaDownloaded,
    zonesDownloaded,
    stopsDownloaded,
    zonesPrinted,
    stopsAssigned,
    updatingZones
  ]);

  useEffect(() => {
    void getShipmentAreas();
    void getDrivers();
    void getServices();
    void getPostalCodes();
    void getDateRanges();
  }, []);

  useEffect(() => {
    console.log('zones', { zones, createZoneMode });
    if (createZoneMode === CreateZoneModes.MAP && !selectedZone) {
      setSelectedZone(zones.find(zone => zone.id === ZoneDefaultID));
    }
    setUpdatingZones(false);
  }, [zones]);

  useEffect(() => {
    console.log('zonesPrinted', zonesPrinted);
  }, [zonesPrinted]);

  const addZone = useCallback(
    zoneData => {
      const zone = {
        ...zoneData,
        type: zoneTypes.FIXED,
        name: '',
        info: '',
        status: {
          code: 'enabled'
        },
        thresholds: {
          stops: ['', '']
        }
      };
      //setStopsAssigned(val => !val);
      console.log('Set zones addZone');
      setZonesPrint([zone, ...zones]);
    },
    [zones]
  );

  useEffect(() => {
    console.log('MapProvider selectedZone useEffect', { selectedZone });
    if (!selectedZone) {
      setPolygons(prevPolygons =>
        prevPolygons.map(polygon => {
          polygon.polygon.setOptions({
            strokeWeight: polygonOptions.strokeWeight,
            fillOpacity: polygonOptions.fillOpacity,
            clickable: false
          });
          return polygon;
        })
      );
      return;
    }
    setPolygons(prevPolygons =>
      prevPolygons.map(polygon => {
        if (polygon.id.startsWith(selectedZone.polygonID || selectedZone.id)) {
          polygon.polygon.setOptions({
            strokeWeight: 4,
            fillOpacity: 0.8,
            clickable: true
          });
        } else {
          polygon.polygon.setOptions({
            strokeWeight: polygonOptions.strokeWeight,
            fillOpacity: 0.2,
            clickable: false
          });
        }
        return polygon;
      })
    );
    console.log('Pre assign stops', { selectedZone, createZoneMode });

    if (
      selectedZone?.geometry?.coordinates.length === 0 &&
      createZoneMode === CreateZoneModes.MAP
    )
      return;
    const newZones = zones.map(z =>
      z.id === selectedZone.id ? selectedZone : z
    );
    /***************************************
     Auto assign zones on select
     change behaviour to speed up app
     MANUAL ASSIGN
     ***************************************/
    //setZonesAssign(newZones);
    setZonesPrint(newZones);
  }, [selectedZone]);

  useEffect(() => {
    console.log('showStops', showStops);
  }, [showStops]);

  const addPolygon = useCallback(path => {
    console.log('Add polygon');
    setSelectedZone(zoneUpdate => {
      const geometry = zoneUpdate.geometry || {
        coordinates: [],
        type: GeometryTypes.MultiPolygon
      };
      return {
        ...zoneUpdate,
        geometry: {
          ...geometry,
          type: GeometryTypes.MultiPolygon,
          coordinates: [...geometry?.coordinates, path]
        }
      };
    });
    setCanChangeToDataMap(true);
  }, []);
  const addPolygons = useCallback(paths => {
    console.log('Add polygons', paths);
    setSelectedZone(zoneUpdate => {
      const geometry = zoneUpdate.geometry || {
        coordinates: [],
        type: GeometryTypes.MultiPolygon
      };
      return {
        ...zoneUpdate,
        geometry: {
          ...geometry,
          type: GeometryTypes.MultiPolygon,
          coordinates: [...geometry?.coordinates, ...paths]
        }
      };
    });
    setCanChangeToDataMap(true);
  }, []);

  const removePolygon = useCallback(
    (polygon, polygons = polygons) => {
      setSelectedZone(zoneUpdate => {
        console.log('RemovePolygon zoneUpdate', { zoneUpdate, polygons });
        const coordinates = polygons
          .filter(poly => {
            if (poly.id.startsWith(zoneUpdate.polygonID || zoneUpdate.id)) {
              console.log('Delete Start with', { poly, polygon, zoneUpdate });
              if (polygon.id !== poly.id) {
                console.log('Delete Different id', {
                  poly,
                  polygon,
                  zoneUpdate
                });
                return true;
              }
              console.log('Delete same id', { poly, polygon, zoneUpdate });
            }
            return false;
          })
          .map(poly => poly.geometry.coordinates);
        console.log('RemovePolygon new coordinates', coordinates);
        return {
          ...zoneUpdate,
          geometry: {
            ...zoneUpdate.geometry,
            type: GeometryTypes.MultiPolygon,
            coordinates
          }
        };
      });
    },
    [polygons]
  );

  /*
    Cancel actions inside a trigger to show loading page to user
   */
  const cancelEditZone = useCallback(() => {
    console.log('MapProvider cancelEdit setZones');
    setUpdatingZones(true);
    setLoading(true);
    setTriggerCancelEditZone(prevVal => !prevVal);
  }, []);

  useEffect(() => {
    if (createZoneMode === CreateZoneModes.DISABLED) return;
    console.log('cancelEdit trigger');
    setZonesPrint(zonesBackup.current);
    zonesBackup.current = [];
    polygons.forEach(polygonData => {
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
    setPolygons(polygonsBackup.current);
    polygonsBackup.current = [];
    setSelectedZone(null);
    setCreateZoneMode(CreateZoneModes.DISABLED);
    resetFilters();
    setMapState(MapStates.DEFAULT);
  }, [triggerCancelEditZone]);

  const resetFilters = () => {
    setFilterServices([]);
    setFilterPostalCodes([]);
    setFilterDateRanges('');
  };

  const deleteZone = useCallback(
    async zone => {
      setLoading(true);
      setUpdatingZones(true);
      try {
        await zonesDelete(zone.code);
        console.log('Set zones on delete');
        const newZones = zones.filter(z => z.id !== zone.id);
        //Hide all polygon zones
        polygons.forEach(polygonData => {
          if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
            polygonData.polygon.setMap(null);
          }
        });
        setPolygons(prevValue =>
          prevValue.filter(
            polygonData => !polygonData.id.startsWith(zone.polygonID || zone.id)
          )
        );
        if (newZones.length > 0) {
          /***************************************
           Auto assign zones after delete zone
           change behaviour to speed up app
           MANUAL ASSIGN
           ***************************************/
          //setStopsAssigned(false);
          //setZonesAssign(newZones);
          setZonesPrint(newZones);
        } else {
          setZones(newZones);
        }
      } catch (e) {
        //Zone not exists, delete it
        console.error('status error', { status: e.status });
        if (e.status === 404) {
          console.log('status error updateData');
          void updateData();
        }
        console.error('Error deleting zone');
        console.error(e);
      }
      setUpdatingZones(false);
    },
    [polygons, zones]
  );
  useEffect(() => {
    console.log('Filter stops Markers', stopsFiltered);
  }, [stopsFiltered]);

  const hideAllPolygons = useCallback(() => {
    history.current.present.forEach(polygonData => {
      console.log('Hide polygon', polygonData);
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
  }, []);

  const historyUndo = useCallback(() => {
    console.log('History Undo', history.current);
    const last = history.current.past.slice(-1)[0];
    if (!last) return false;
    hideAllPolygons();
    history.current = {
      past: [...history.current.past].slice(0, -1),
      present: last,
      future: history.current.present
        ? [...history.current.future, history.current.present]
        : history.current.future
    };
    console.log('History Undo', history.current);
    setPolygons(last);
    return true;
  }, []);

  const historyRedo = useCallback(() => {
    const next = history.current.future.slice(-1)[0];
    if (!next) return false;
    hideAllPolygons();
    history.current.present.forEach(polygonData => {
      console.log('Hide polygon', polygonData);
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
    history.current = {
      past: history.current.present
        ? [...history.current.past, history.current.present]
        : history.current.past,
      present: next,
      future: [...history.current.future].slice(0, -1)
    };
    console.log('History Redo', history.current);
    setPolygons(next);
    return true;
  }, []);

  const historySetPresent = useCallback(present => {
    history.current = {
      past: [...history.current.past, history.current.present],
      present,
      future: []
    };

    console.log('History set present', history.current);
  }, []);

  useEffect(() => {
    console.log('Change loading', { loading });
  }, [loading]);

  useEffect(() => {
    console.log('Filters', {
      filterServices,
      filterPostalCodes,
      filterDateRanges
    });
  }, [filterServices, filterPostalCodes, filterDateRanges]);

  const memoizedContextValue = useMemo(
    () => ({
      createZone,
      triggerClearZone,
      clearZones,
      zonesAssign,
      setZonesAssign,
      zonesPrint,
      setZonesPrint,
      zones,
      setZones,
      zonesBackup,
      showStops,
      setShowStops,
      stops,
      setStops,
      shipmentAreas,
      setShipmentAreas,
      polygons,
      setPolygons,
      polygonsBackup,
      centerArea,
      zonesDownloaded,
      addZone,
      addPolygon,
      addPolygons,
      zonesPrinted,
      setZonesPrinted,
      createZoneMode,
      setCreateZoneMode,
      selectedZone,
      setSelectedZone,
      cancelEditZone,
      saveZone,
      stopsAssigned,
      setStopsAssigned,
      centerZone,
      mapBounds,
      deleteZone,
      updateZone,
      updatePriorityZone,
      updateStatusZone,
      setUpdatingZones,
      history,
      removePolygon,
      historyUndo,
      historyRedo,
      historySetPresent,
      mapState,
      setMapState,
      drivers,
      services,
      filterServices,
      setFilterServices,
      postalCodes,
      filterPostalCodes,
      setFilterPostalCodes,
      dateRanges,
      filterDateRanges,
      setFilterDateRanges,
      stopsFiltered,
      setLoading,
      manualAssign
    }),
    [
      createZone,
      triggerClearZone,
      clearZones,
      zonesAssign,
      setZonesAssign,
      zonesPrint,
      setZonesPrint,
      zones,
      setZones,
      zonesBackup,
      showStops,
      setShowStops,
      stops,
      setStops,
      shipmentAreas,
      setShipmentAreas,
      polygons,
      setPolygons,
      polygonsBackup,
      centerArea,
      zonesDownloaded,
      addZone,
      addPolygon,
      zonesPrinted,
      setZonesPrinted,
      createZoneMode,
      setCreateZoneMode,
      selectedZone,
      setSelectedZone,
      cancelEditZone,
      saveZone,
      stopsAssigned,
      setStopsAssigned,
      centerZone,
      mapBounds,
      deleteZone,
      updateZone,
      updatePriorityZone,
      updateStatusZone,
      setUpdatingZones,
      history,
      removePolygon,
      historyUndo,
      historyRedo,
      historySetPresent,
      mapState,
      setMapState,
      drivers,
      services,
      filterServices,
      setFilterServices,
      postalCodes,
      filterPostalCodes,
      setFilterPostalCodes,
      dateRanges,
      filterDateRanges,
      setFilterDateRanges,
      stopsFiltered,
      setLoading,
      manualAssign
    ]
  );

  return (
    <MapContext.Provider value={memoizedContextValue}>
      {children}
      {loading && (
        <Suspense fallback={<LoadingFallback />}>
          <LoadingScreen />
        </Suspense>
      )}
    </MapContext.Provider>
  );
};

// Función de comparación personalizada para memo
const areEqual = (prevProps, nextProps) => {
  // Comparar solo las props que realmente afectan al renderizado
  return (
    prevProps.children === nextProps.children && prevProps.t === nextProps.t
  );
};

// Aplicar memo con la función de comparación personalizada
const MapProvider = memo(MemoizedMapProvider, areEqual);

// Aplicar HOC de traducción al componente memorizado
export default withTranslation()(MapProvider);

MapProvider.propTypes = {
  t: PropTypes.func,
  children: PropTypes.any
};
export const MapContext = createContext();
